import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import Header from "../components/Header"
import CourseSearch from "../components/CourseSearch"
import { FaWaze, FaPhone, FaMapMarked } from "react-icons/fa"
import { MdGolfCourse } from "react-icons/md"

export const query = graphql`
  query($slug: String!) {
    courseJson(slug: { eq: $slug }) {
      slug
      course_name
      waze
      phone
      google_maps
      tee_time
      holes
      type
      website
      score_cards {
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default function Course({ data }) {
  const course = data.courseJson
  console.log(course)
  const score_cards = course.score_cards.map(score_card => {
    if (score_card.image !== null) {
      return (
        <Image
          key={score_card.image.childImageSharp.fluid.src}
          fluid={score_card.image.childImageSharp.fluid}
        />
      )
    }
  })

  return (
    <Container fluid="md">
      <Row>
        <Col lg={12}>
          <Header />
          <CourseSearch />
          <h2>{course.course_name}</h2>
        </Col>
      </Row>
      <Row className="top-buffer">
        <Col>
          <Button size="lg" href={course.waze} target="_blank" block>
            <FaWaze style={{marginRight:"20px"}} />Waze
          </Button>
        </Col>
      </Row>
      <Row className="top-buffer">
        <Col>
          <Button size="lg" href={course.google_maps} target="_blank" block>
            <FaMapMarked style={{marginRight:"20px"}} />Google Maps
          </Button>
        </Col>
      </Row>
      <Row className="top-buffer">
        <Col lg={12}>
          <Button block size="lg" href={"tel:" + course.phone.toString()}>
            <FaPhone style={{marginRight:"20px"}} />Call Course
          </Button>
        </Col>
      </Row>
      {course.tee_time !== "" && (
        <Row className="top-buffer">
          <Col lg={12}>
            <Button block size="lg" href={course.tee_time} target="_blank">
              <MdGolfCourse style={{marginRight:"20px"}} />Book Tee Time
            </Button>
          </Col>
        </Row>
      )}
      <Row className="top-buffer">
        <Col lg={12}>
          <h3>Info</h3>
          <p>{ course.type }</p>
          <p>{ course.holes } Holes</p>
          <p><a href={ course.website } target="_blank">Course Website</a></p>
        </Col>
      </Row>
      <Row className="top-buffer">
        <Col lg={8}>{score_cards}</Col>
      </Row>
    </Container>
  )
}
